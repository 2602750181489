export const onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    await import(`intersection-observer`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement('img')
  if (
    typeof testImg.style.objectFit === 'undefined' ||
    typeof testImg.style.objectPosition === 'undefined'
  ) {
    import('object-fit-images').then((res) => {
      res.default()
    })
  }

  window.dataLayer = window.dataLayer || []
}

export const onRouteUpdate = ({ location }) => {
  if (!window.grecaptcha && location.pathname === '/contact/') {
    const s = document.createElement('script')
    s.src =
      'https://www.google.com/recaptcha/api.js?render=6Lcg_nsUAAAAAKndIgLUB9tXroXMeJDe7d0Y6gwx'
    document.head.appendChild(s)
  }
}
