exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-suits-js": () => import("./../../../src/pages/business-suits.js" /* webpackChunkName: "component---src-pages-business-suits-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-shoes-js": () => import("./../../../src/pages/custom-shoes.js" /* webpackChunkName: "component---src-pages-custom-shoes-js" */),
  "component---src-pages-etch-js": () => import("./../../../src/pages/etch.js" /* webpackChunkName: "component---src-pages-etch-js" */),
  "component---src-pages-events-gala-dinner-bond-fashion-show-2021-js": () => import("./../../../src/pages/events/gala-dinner-bond-fashion-show-2021.js" /* webpackChunkName: "component---src-pages-events-gala-dinner-bond-fashion-show-2021-js" */),
  "component---src-pages-events-gareth-gates-march-2020-js": () => import("./../../../src/pages/events/gareth-gates-march-2020.js" /* webpackChunkName: "component---src-pages-events-gareth-gates-march-2020-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loyalty-scheme-js": () => import("./../../../src/pages/loyalty-scheme.js" /* webpackChunkName: "component---src-pages-loyalty-scheme-js" */),
  "component---src-pages-made-to-measure-tailoring-js": () => import("./../../../src/pages/made-to-measure-tailoring.js" /* webpackChunkName: "component---src-pages-made-to-measure-tailoring-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-rewards-js": () => import("./../../../src/pages/referral-rewards.js" /* webpackChunkName: "component---src-pages-referral-rewards-js" */),
  "component---src-pages-sports-jackets-blazers-js": () => import("./../../../src/pages/sports-jackets-blazers.js" /* webpackChunkName: "component---src-pages-sports-jackets-blazers-js" */),
  "component---src-pages-tailored-shirts-js": () => import("./../../../src/pages/tailored-shirts.js" /* webpackChunkName: "component---src-pages-tailored-shirts-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-wedding-suits-js": () => import("./../../../src/pages/wedding-suits.js" /* webpackChunkName: "component---src-pages-wedding-suits-js" */)
}

